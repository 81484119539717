import React from 'react'

import './Footer.scss';

export default function Footer() {
  return (
    <div className="footer__container">
      <p className="footer__text">Joe Chaiet &copy; 2020</p>
    </div>
  )
}
